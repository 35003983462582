.layout-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.box {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 10px;
    text-align: center;
    min-width: 140px;
}

.green {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.red {
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.yellow {
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.number{
    display: block;
    font-size: 12px;
}
/* Add styles for lines connecting boxes (can use pseudo-elements or SVG) */
